import { required, email, helpers } from '@vuelidate/validators'

import emailProviders from '@/data/emailProviders.json'

const useValidation = () => {
  const { t } = useI18n()

  const checkBusinessEmail = (email: string) => {
    return !emailProviders.some((element) => {
      return email.toLowerCase().includes('@' + element)
    })
  }

  const isFieldRequired = helpers.withMessage(
    t('common.form.validation.field.error'),
    required,
  )
  const isEmailValid = helpers.withMessage(
    t('common.form.validation.email.invalid'),
    email,
  )
  const isEmailBusiness = helpers.withMessage(
    t('common.form.validation.email.business'),
    (val: string) => checkBusinessEmail(val),
  )
  const isConsentAccepted = helpers.withMessage(
    t('common.form.validation.consent.error'),
    (val: boolean) => val === true,
  )
  const isValueSelected = helpers.withMessage(
    t('common.form.validation.checkbox.error'),
    (val: string) => val !== '',
  )

  const isCheckboxGroupRequired = helpers.withMessage(
    t('common.form.validation.checkbox_group.error'),
    required,
  )

  return {
    isEmailBusiness,
    isEmailValid,
    isConsentAccepted,
    isValueSelected,
    isFieldRequired,
    isCheckboxGroupRequired,
  }
}
export default useValidation
